<template>
  <div>
    <viewcard--c title="Ocorrências">
      <b-row class="mb-1 d-flex justify-content-end">
        <b-col>
          <b-button
            v-if="buttonEntity"
            variant="info"
            @click="goRoute('audiovisual-occurrence-entity')"
            id="button-occurrence-entity"
          >
            <feather-icon icon="GridIcon" />
            <b-tooltip
              triggers="hover"
              target="button-occurrence-entity"
              title="Entidade"
              :delay="{ show: 100, hide: 50 }"
            />
          </b-button>

          <b-button
            v-if="buttonCategory"
            class="ml-1"
            variant="info"
            @click="goRoute('audiovisual-occurrence-category')"
            id="button-occurrence-category"
          >
            <feather-icon icon="ListIcon" />
            <b-tooltip
              triggers="hover"
              target="button-occurrence-category"
              title="Categorias"
              :delay="{ show: 100, hide: 50 }"
            />
          </b-button>
        </b-col>
        <b-col md="5" class="mt-1">
          <b-input-group>
            <b-form-input
              placeholder="Pesquise por Descrição..."
              autocomplete="off"
              v-model="search"
              @keyup.enter="filter"
            />
            <b-input-group-append>
              <b-button variant="info" @click="filter">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span>Pesquisar</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <view--c :permission="'permission.job.occurrence.view'" :busy="isloading">
        <Table
          :fields="fields"
          :list="
            !isloading && list[currentePage] ? list[currentePage].itens : []
          "
          @orderBy="getRecordsOrderBy"
          @rowClick="onClickSelected"
          border="full"
          responsive
        >
          <template #appointment_id="data">
            #{{ data.item.relation_key }}
          </template>
          <template #teacher="data">
            <div>
              <div v-if="data.item.teacher">
                <span v-if="data.item.teacher.length > 20">
                  {{ data.item.teacher.substring(0, 12) }}...
                  <feather-icon
                    icon="HelpCircleIcon"
                    :id="`tooltip-ocurrence-${data.item.id}`"
                  />
                  <b-tooltip
                    triggers="hover"
                    :target="`tooltip-ocurrence-${data.item.id}`"
                    :title="data.item.teacher"
                    :delay="{ show: 100, hide: 50 }"
                  />
                </span>
                <span v-else>{{ data.item.teacher }} </span>
              </div>
              <span v-else>*</span>
            </div>
          </template>
          <template #event_or_lesson="data">
            <div>
              <span v-if="data.item.event_or_lesson.length > 30">
                {{ data.item.event_or_lesson.substring(0, 29) }}...
                <feather-icon
                  icon="HelpCircleIcon"
                  :id="`tooltip-ocurrence-event-${data.item.id}`"
                />
                <b-tooltip
                  triggers="hover"
                  :target="`tooltip-ocurrence-event-${data.item.id}`"
                  :title="data.item.event_or_lesson"
                  :delay="{ show: 100, hide: 50 }"
                />
              </span>
              <span v-else>{{ data.item.event_or_lesson }} </span>
            </div>
          </template>
        </Table>

        <b-pagination
          v-model="currentePage"
          @change="getLoadMore"
          :total-rows="rows"
          :per-page="size"
          v-if="rows > 1"
          first-number
          last-number
          align="center"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </view--c>
    </viewcard--c>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import _jobOccurrenceService from "@/services/audiovisual/job-occurrence-service";
export default {
  components: {
    Table,
  },
  data() {
    return {
      btnew: {
        permission: "permission.job.occurrence.create",
        to: "/audio-visual/occurrence/job-occurrence/0",
      },
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        {
          key: "relation_key",
          label: "ID",
          orderBy: { type: "back" },
        },
        {
          key: "category",
          label: "Categoria",
          orderBy: { type: "back" },
        },
        {
          key: "entity",
          label: "Entidade",
          orderBy: { type: "back" },
        },
        { key: "company", label: "Empresa" },
        { key: "teacher", label: "Professor" },
        { key: "operator_name", label: "Operador" },
        {
          key: "event_or_lesson",
          label: "Evento/Aula",         
        },
        { key: "description", label: "Descrição" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: "",
        ascOrDes: false,
        orderByKey: "relation_key",
      },
      buttonEntity: false,
      buttonCategory: false,
    };
  },
  created() {
    this.buttonEntity = this.$utils.isPermited(
      "permission.occurrence.entity.view"
    );
    this.buttonCategory = this.$utils.isPermited(
      "permission.occurrence.category.view"
    );
    this.getRecords(this.currentePage);
  },
  methods: {
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _jobOccurrenceService
        .show(_page, this.orderByParams)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 20;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    onClickSelected(record, _) {
      this.$router.push({
        path: `/audio-visual/occurrence/job-occurrence/${record.id}`,
      });
    },
    goRoute(_name) {
      this.$router.push({ name: _name });
    },
  },
};
</script>